export class GraphAPI {
  /**
   * Helper function to call MS Graph API endpoint
   * using the authorization bearer token scheme.
   */
  static callMSGraph(
    endpoint: string,
    token: unknown,
    callback: (response: unknown, endpoint: string) => void
  ): void {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append('Authorization', bearer);

    const options = {
      method: 'GET',
      headers: headers,
    };

    console.info('Request made to Graph API at: ' + new Date().toString());

    fetch(endpoint, options)
      .then((response) => response.json())
      .then((response) => callback(response, endpoint))
      .catch((error) => console.error(error));
  }
}
